<template>
    <div>
        <CRow>
            <CCol sm="12">
                <CCard>
                    <CCardHeader>
                        <strong>Add User Group</strong>
                    </CCardHeader>
                    <CCardBody>
                        <CForm @keyup.enter="validator();submit()">
                            <CRow>
                                <CCol sm="3">
                                    <CInput v-model="form.name.val" :description="form.name.inv" label="Name" />
                                </CCol>
                                <CCol sm="3">
                                    <CSelect
                                        v-model="form.is_active.val"
                                        :description="form.is_active.inv"
                                        label="State"
                                        :options="[
                                            {value: 'true', label:'Active'},
                                            {value: 'false', label:'Inactive'},
                                        ]"
                                        placeholder="Please Select"
                                        @change="form.is_active.val=$event.target.value"
                                    />
                                </CCol>
                            </CRow>
                            <br>
                        </CForm>
                        <br>
                        <div class="form-group form-actions">
                            <CButton @click="validator();submit()" color="primary"> Submit</CButton>
                        </div>    
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: {val: '', inv: '', v: { req: true, min: 2, max: 1024 }},
                is_active: {val: '', inv: '', v: { req: true, max: 1024 }},
            },
            disable: false,
        }
    },
    methods: {
        validator() {
            for (const key in this.form) {
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form) {
                for (const k in this.form[key].v) {
                    switch (k) {
                        case 'req':
                            if (this.form[key].val == '') {
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length > this.form[key].v[k]) {
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'min':
                            if (this.form[key].val != '') {
                                if (this.form[key].val.length < this.form[key].v[k]) {
                                    this.form[key].inv = `Must be at least ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                    }
                }
            }
        },
        submit(){
            if(this.disable == false){
                const data = new FormData();
                for (const key in this.form){
                    data.set(key, this.form[key].val);
                }
                this.axios.post('system/user_groups/', data)
                    .then(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: 'Submitted Successfully.'
                        });
                        this.$router.push('/groups');
                    })
                    .catch(() => {
                        this.$notify({
                            group: 'foo',
                            type: 'error',
                            text: 'Error! please try again.'
                        });
                    })
            }
        }
    }
}
</script>
